import AxiosWrapper from '~/assets/javascript/AxiosWrapper';
import OnSelect from '~/components/common/stores/OnSelect.js'
import SelectHelpers from '~/components/common/stores/SelectHelpers.js'
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'
import cloneDeep from 'lodash/cloneDeep'
import mergeWith from 'lodash/mergeWith'
import isArray from 'lodash/isArray'
import { defaultParams, defaultConfig } from '~/components/common/stores/DefaultSelectConfig.js'
import camelCase from 'lodash/camelCase'

import { getField, updateField } from 'vuex-map-fields';
import api from '~/components/common/Select/api';

const getDefaultState = () => ({
  refreshQueue: {},
  registeredComponents: [],
  zones: [],
  selectedZones: [],
  shiftDescriptions: [],
  selectedShiftDescriptions: [],
  jobs: [],
  selectedJobs: [],
  tags: [],
  selectedTags: [],
  ranks: [],
  selectedRanks: [],
  departments: [],
  selectedDepartments: [],
  roles: [],
  selectedRoles: [],
  companyNames: [],
  selectedCompanyNames: [],
  partNumbers: [],
  selectedPartNumbers: [],
  serialNumbers: [],
  selectedSerialNumbers: [],
  lotNumbers: [],
  selectedLotNumbers: [],
  other1s: [],
  selectedOther1s: [],
  other2s: [],
  selectedOther2s: [],
  selectedJobStatus: "active",
  searchBy: "job",
  jobRevisions: [],
  selectedJobRevisions: [],
  suspectCodeModels: [],
  selectedSuspectCodeModels: [],
  suspectCodes: [],
  selectedSuspectCodes: [],
  questionnaireGroups: [],
  selectedQuestionnaireGroups: [],
  questionnaires: [],
  selectedQuestionnaires: [],
  users: [],
  selectedUsers: [],
  binUsers: [],
  selectedBinUsers: [],
  exams: [],
  selectedExams: [],
  workAreas: [],
  selectedWorkAreas: [],
  posts: [],
  selectedPosts: [],
  selectedVersions: [],
  versions: [],
  timeFrames: [],
  selectedTimeFrames: [],
  isProcessInitial: true,
  showActiveJobs: true,
  params: {},
  names: {},
  config: {},
  storedJobConfig: null,
  storedSuspectCodeModelsConfig: null,
  storedSuspectCodesConfig: null,
  storedDepartmentsConfig: null,
  isCustomer: null,
  testing: false,
  api,
  customValues: {
    company_name: null,
    part_number: null,
    serial_number: null,
    lot_number: null,
    other1: null,
    other2: null
  },
  initInProgress: false
})

export const state = getDefaultState

export const mutations = {
  updateField,
  resetCustomValues: state => {
    state.customValues = getDefaultState().customValues
  },
  resetStateWithDefaultApi(state) {
    Object.assign(state, getDefaultState())
  },
  resetState(state) {
    let newState = getDefaultState()
    delete newState.api
    Object.assign(state, newState)
  },
  setParams(state, { params, paramsMap = null }) {
    state.params = params
    Object.entries(params).forEach(keyValue => {
      let key = keyValue[0].match(/(custom_)(.+)/)
      if (key && key[2]) {
        state.customValues[key[2]] = keyValue[1]
        delete state.params[keyValue[0]]
      }
    })
    let component;

    Object.entries(paramsMap || ConfigHelper.defaultParamsMap()).forEach(keyValue => {
      component = state.config[camelCase(keyValue[0] + 's')]
      if (component && !component.multiple && isArray(state.params[keyValue[1]])) {
        state.params[keyValue[1]] = state.params[keyValue[1]][0]
      }
    })
  },
  resetRegisteredComponents(state) {
    state.registeredComponents = []
    state.storedJobConfig = null
    state.storedSuspectCodeModelsConfig = null
    state.storedSuspectCodesConfig = null
    state.config = {}
  }
}

export const getters = {
  getField,
  paramsWithCustomValues: state => {
    let params = cloneDeep(state.params)

    Object.entries(state.customValues).forEach(keyValue => {
      if (keyValue[1] && keyValue[1].length) params[keyValue[0]] = keyValue[1]
    })

    return params
  },
  prefixedCustomValues: state => {
    let customValues = {}
    Object.entries(state.customValues).forEach(keyValue => {
      if (keyValue[1]) {
        customValues[`custom_${keyValue[0]}`] = keyValue[1];
        delete customValues[keyValue[0]]
      }
    })
    return customValues
  },
  partNumberConstraintLabel: (state) => SelectHelpers.getJobLabel(state.selectedJobs, "part_number"),
  hasPartNumbers: (state) => SelectHelpers.containsValuesForLabel(state, "partNumbers"),
  serialNumberConstraintLabel: (state) => SelectHelpers.getJobLabel(state.selectedJobs, "serial_number"),
  hasSerialNumbers: (state) => SelectHelpers.containsValuesForLabel(state, "serialNumbers"),
  lotNumberConstraintLabel: (state) => SelectHelpers.getJobLabel(state.selectedJobs, "lot_number"),
  hasLotNumbers: (state) => SelectHelpers.containsValuesForLabel(state, "lotNumbers"),
  other1ConstraintLabel: (state) => SelectHelpers.getJobLabel(state.selectedJobs, "other1"),
  hasOther1s: (state) => SelectHelpers.containsValuesForLabel(state, "other1s"),
  other2ConstraintLabel: (state) => SelectHelpers.getJobLabel(state.selectedJobs, "other2"),
  hasOther2s: (state) => SelectHelpers.containsValuesForLabel(state, "other2s")
}

export const actions = {
  async registerSearchReset({ commit, state }, replaceDefaultReset) {
    if (replaceDefaultReset !== null) {
      commit('updateField', { path: 'config.reset', value: replaceDefaultReset })
    }

    commit('updateField', { path: 'config.reset.onSelect', value: state.config.reset.onReset(state) })
    return Promise.resolve()
  },
  async registerComponent({ commit, state, rootGetters }, { incoming, config = {} }) {
    if (state.isCustomer === null) {
      commit('updateField', { path: 'isCustomer', value: rootGetters['login/isCustomer'] })
    }

    let customConfig = cloneDeep(state.config)
    let incomingConfig = customConfig[incoming]

    incomingConfig = mergeWith(incomingConfig, config, (objValue, srcValue) => {
      if (isArray(srcValue)) {
        return srcValue
      }

      if (srcValue === undefined) {
        if (state.isCustomer && isArray(objValue)) {
          objValue = objValue.filter(req => req !== 'zone_ids')
        }
        return objValue
      }
    })

    if (incoming === 'jobs' && incomingConfig.required && incomingConfig.required.length === 0) {
      commit("updateField", { path: "config.jobs", value: incomingConfig })
      commit("updateField", { path: "storedJobConfig", value: incomingConfig })
    }

    if (incoming === 'suspectCodeModels' && incomingConfig.required && incomingConfig.required.length === 0) {
      commit("updateField", { path: "config.suspectCodeModels", value: incomingConfig })
      commit("updateField", { path: "storedSuspectCodeModelsConfig", value: incomingConfig })
    }

    if (incoming === 'suspectCodes' && incomingConfig.required) {
      commit("updateField", { path: "config.suspectCodes", value: incomingConfig })
      commit("updateField", { path: "storedSuspectCodesConfig", value: incomingConfig })
    }

    if (incoming === 'departments' && incomingConfig.required && incomingConfig.required.length === 0) {
      commit("updateField", { path: "config.departments", value: incomingConfig })
      commit("updateField", { path: "storedDepartmentsConfig", value: incomingConfig })
    }

    let components = cloneDeep(state.registeredComponents)
    components.push(incoming)
    commit("updateField", { path: "registeredComponents", value: components })

  },
  dispatchToEventQueue({ dispatch }, { action, args }) {
    if (this.lastInit) {
      this.lastInit = this.lastInit.then(() => dispatch(action, args))
    } else {
      this.lastInit = dispatch(action, args)
    }
  },
  chainingInit({ dispatch }, initObj) {
    dispatch("dispatchToEventQueue", { action: "init", args: initObj })
  },
  async init(
    { commit, dispatch, state },
    {
      params = {},
      paramsMap = null,
      reset = null,
      config = null
    }) {

    commit('updateField', { path: "initInProgress", value: true })

    let mergedConfig = cloneDeep(defaultConfig);
    mergeWith(mergedConfig, config, (objValue, srcValue) => {
      if (isArray(srcValue)) {
        return srcValue
      }
    })

    commit('updateField', { path: "config", value: mergedConfig })

    if (state.storedJobConfig) {
      commit('updateField', { path: "config.jobs.required", value: state.storedJobConfig.required })
      commit('updateField', { path: "config.jobs.onInit", value: state.storedJobConfig.onInit })
      commit('updateField', { path: "storedJobConfig", value: null })
    }

    if (state.storedSuspectCodeModelsConfig) {
      commit('updateField', { path: "config.suspectCodeModels.required", value: state.storedSuspectCodeModelsConfig.required })
      commit('updateField', { path: "config.suspectCodeModels.onInit", value: state.storedSuspectCodeModelsConfig.onInit })
    }

    if (state.storedSuspectCodesConfig) {
      commit('updateField', { path: "config.suspectCodes.required", value: state.storedSuspectCodesConfig.required })
    }

    if (state.storedDepartmentsConfig) {
      commit('updateField', { path: "config.departments.required", value: state.storedDepartmentsConfig.required })
    }

    let searchReset = dispatch('registerSearchReset', reset)

    return searchReset.then(() => {
      if (Object.keys(params).length > 0) {
        if (paramsMap !== null) {
          params = ConfigHelper.mapParams(params, paramsMap)
        }

        if (params.job_status) {
          commit("updateField", { path: "selectedJobStatus", value: params.job_status })
        }

        if (params.search_by) {
          commit("updateField", { path: "searchBy", value: params.search_by })
        }

        return dispatch('setPreviousParams', { params, paramsMap })
          .then(() => commit('updateField', { path: "initInProgress", value: false }))
      } else {
        commit('setParams', { params: defaultParams() });
        return dispatch('resetAndPopulateAllOnSelectForType', 'startUp')
          .then(() => commit('updateField', { path: "initInProgress", value: false }))
      }
    })
  },
  async setPreviousParams({ commit, dispatch, state }, { params, paramsMap = null }) {
    commit('setParams', { params: { ...defaultParams(), ...params }, paramsMap });
    let queue = OnSelect.createQueue(state, cloneDeep(state.registeredComponents));
    commit("updateField", { path: "refreshQueue", value: queue })
    return dispatch('next')
  },
  async next({ dispatch, state }) {
    let mixedParams = state.refreshQueue.getParamsForLevel(state)
    return dispatch('populateSelect', { params: mixedParams, selectedType: state.refreshQueue.typeSelected })
  },
  async nextWorkflow({ commit, dispatch, state }) {
    if (Object.keys(state.refreshQueue).length > 0) {
      if (state.refreshQueue.isComplete()) {
        commit("updateField", { path: "refreshQueue", value: getDefaultState().refreshQueue })
        return Promise.resolve()
      } else {
        var refreshQueue = cloneDeep(state.refreshQueue)
        refreshQueue.next();
        commit("updateField", { path: "refreshQueue", value: refreshQueue })
        return dispatch('next')
      }
    } else {
      return Promise.resolve()
    }
  },
  async populateSelect({ commit, dispatch, state }, { params, selectedType }) {
    let querySearch = AxiosWrapper.getSearchParams()
    if (querySearch.device) params = { ...params, device: querySearch.device }

    let filteredParams = SelectHelpers.createFilteredParams(params, state.registeredComponents)

    let compressedParams = SelectHelpers.createCompressedParamsFrom(cloneDeep(filteredParams))

    let promises = []
    let axiosPromise = Promise.resolve()

    if (compressedParams) {
      const paramsBlock = {
        ...compressedParams,
        job_status: state.selectedJobStatus,
        search_by: state.searchBy,
        select_type: selectedType
      }

      if (state.selectedTags.length > 0) {
        const tagValues = state.selectedTags.map(tag => parseInt(tag.value));
        paramsBlock.job_tags = tagValues
      }

      axiosPromise = state.api.getMixed(paramsBlock, this.$axios).then(response => {
        if (state.isProcessInitial) {
          commit('updateField', { path: "isProcessInitial", value: false });
        }

        if (response.data != null) {
          commit("updateField", { path: "params.job_status", value: state.selectedJobStatus })
          commit("updateField", { path: "params.search_by", value: state.searchBy })
          Object.keys(params).forEach(type => {
            let invidualParams = params[type];
            if (response.data.hasOwnProperty(type) && response.data[type].data) {
              let select = SelectHelpers.createSelect(response.data[type].data);
              commit('updateField', { path: invidualParams.type, value: select });
              promises.push(dispatch('updateSelectedAndParams', { type: invidualParams.type }))
            }
          });
          promises.push(dispatch('nextWorkflow'))
        }

        return Promise.all(promises)
      });
    }
    return axiosPromise
  },
  async updateSelectedAndParams({ commit, state }, { type }) {
    try {
      let oldValues = state.refreshQueue.oldValuesForType ? state.refreshQueue.oldValuesForType(type) : null

      if (oldValues != null) {
        let options = SelectHelpers.returnSelectOptions(state, type, oldValues)

        if (options.length > 0) {
          let selectedName = SelectHelpers.getSelectedName(type)
          commit('updateField', { path: selectedName, value: options });

          let selectedValueParam = SelectHelpers.selectedValueParam(state, type)
          commit('updateField', selectedValueParam);

          let selectedValueName = SelectHelpers.selectedValueName(state, type)
          commit('updateField', selectedValueName);

        }
      }
    } catch (e) {
      console.log(
        `
        Encountered error, probably caused by temporal couplings in selects.
        When selects axios calls from a previous init return during a new
        selects initialization, errors like this can occur. This can happen when a
        user changes pages or tabs while selects is initializing. Since I caught
        this error nothing bad will probably happen, as selects will continue the
        new initialization normally
        \n`, e)
    }

    return Promise.resolve()
  },
  async resetAndPopulateAllOnSelectForType({ commit, dispatch, state }, type) {
    let refreshQueue = OnSelect.createQueue(state, type);
    let resetList = OnSelect.createResetList(state, type);

    let promises = []

    resetList.forEach(resetType => {
      promises.push(dispatch('resetOnSelected', resetType))
    });

    if (refreshQueue.dependencies.length > 0) {
      commit("updateField", { path: "refreshQueue", value: refreshQueue })
      promises.push(dispatch('next'))
    }

    return Promise.all(promises)
  },
  async resetOnSelected({ commit, state }, type) {
    commit('updateField', { path: type, value: [] });
    commit('updateField', { path: SelectHelpers.getNamePath(type), value: null });

    commit('updateField', { path: SelectHelpers.getParamPath(state, type), value: null });
    commit('updateField', { path: SelectHelpers.getSelectedName(type), value: [] });
  },
  async selected({ commit, dispatch, state }, type) {
    let selectedValueParam = SelectHelpers.selectedValueParam(state, type)
    commit('updateField', selectedValueParam);

    let selectedValueName = SelectHelpers.selectedValueName(state, type)
    commit('updateField', selectedValueName);

    dispatch('resetAndPopulateAllOnSelectForType', type)
  },
  async resetSelects({ state, dispatch, commit }) {
    commit("resetCustomValues")

    if (state.config.reset) {
      let resetSelectNames = state.config.reset.onReset(state);
      resetSelectNames.forEach(type => dispatch('resetOnSelected', type))
      return Promise.all([
        dispatch('resetAndPopulateAllOnSelectForType', "reset"),
        dispatch('datetime/setDefaultParams', false, { root: true })
      ])
    } else {
      return Promise.resolve()
    }
  },
  reInitSelects({ dispatch }, params) {
    return dispatch("resetSelects").then(() => dispatch("init", { params }))
  },
  setOtherAllowed({ commit }, field) {
    commit('setOtherAllowed', field)
  },
  async initForReportPage({ dispatch }, { params, paramsMap, config }) {
    dispatch("chainingInit", { params, paramsMap, config }).then(() => {
      dispatch("datetime/init", { params, isReportPage: true }, { root: true });
    });
  }
}