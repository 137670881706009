import SelectHelper from '~/components/common/stores/SelectHelpers.js'
import ConfigHelper from '~/components/common/stores/ConfigHelper.js'

const defaultParams = () => ({
  zone_ids: null,
  job_ids: null,
  tag_ids: null,
  rank_ids: null,
  job_revision_ids: null,
  defect_code_model_ids: null,
  questionnaire_group_ids: null,
  questionnaire_ids: null,
  shift_description_ids: null,
  user_ids: null,
  suspect_codes: null,
  company_name: null,
  part_numbers_constraint_ids: null,
  serial_numbers_constraint_ids: null,
  lot_numbers_constraint_ids: null,
  other1s_ids: null,
  other2s_ids: null,
  time_frame_ids: null,
  only_global_suspect_code_models: true,
  work_area_ids: null,
  post_ids: null,
  exam_ids: null,
  versions: null,
  job_status: 'active',
  search_by: 'job'
});

const defaultConfig = {
  reset: {
    onInit: [],
    onReset(state) {
      let array = state.isCustomer ? ["jobs"] : ["zones", "exams"]
      return array.concat(["shiftDescriptions", "users"])
    },
    onSelect: [],
  },
  startUp: {
    onInit: [],
    onSelect: ['exams', 'zones', 'ranks'],
  },
  zones: {
    conditionalDispatch(state) {
      return ConfigHelper.meetingRequirements(state, 'zones')
    },
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedZones);
    },
    query_param: 'zone_ids',
    required: [],
    onInit: [],
    onSelect: ['questionnaireGroups',
      'jobs', 'users', 'binUsers', 'workAreas', 'posts',
      'shiftDescriptions', 'departments', 'tags', 'companyNames'],
  },
  workAreas: {
    conditionalDispatch(state) {
      return ConfigHelper.meetingRequirements(state, 'workAreas')
    },
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedWorkAreas);
    },
    query_param: 'work_area_ids',
    required: ['zone_ids'],
    onInit: ['zone_ids', 'job_ids'],
    onSelect: ['posts'],
  },
  posts: {
    conditionalDispatch(state) {
      return ConfigHelper.meetingRequirements(state, 'posts')
    },
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedPosts);
    },
    query_param: 'post_ids',
    required: ['zone_ids', 'work_area_ids'],
    onInit: ['zone_ids', 'job_ids', 'work_area_ids'],
    onSelect: [],
  },
  exams: {
    conditionalDispatch(state) {
      return ConfigHelper.meetingRequirements(state, 'exams')
    },
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedExams);
    },
    query_param: 'exam_ids',
    required: [],
    onInit: [],
    onSelect: ['versions'],
  },
  versions: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedVersions);
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'versions') },
    required: ['exam_ids'],
    query_param: 'versions',
    onInit: ['exam_ids'],
    onSelect: [],
  },
  questionnaireGroups: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedQuestionnaireGroups);
    },
    conditionalDispatch(state) {
      return ConfigHelper.meetingRequirements(state, 'questionnaireGroups')
    },
    query_param: 'questionnaire_group_ids',
    required: ['zone_ids'],
    onInit: ['zone_ids'],
    onSelect: ['questionnaires'],
  },
  questionnaires: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedQuestionnaires);
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'questionnaires') },
    query_param: 'questionnaire_ids',
    required: ['questionnaire_group_ids'],
    onInit: ['questionnaire_group_ids'],
    onSelect: [],
  },
  shiftDescriptions: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedShiftDescriptions)
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'shiftDescriptions') },
    query_param: 'shift_description_ids',
    required: [],
    onInit: ['zone_ids'],
    onSelect: ['timeFrames'],
  },
  timeFrames: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedTimeFrames)
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'timeFrames') },
    query_param: 'time_frame_ids',
    required: [],
    onInit: ['shift_description_ids'],
    onSelect: []
  },
  jobs: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedJobs);
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'jobs') },
    required: ['zone_ids'],
    query_param: 'job_ids',
    onInit: ['zone_ids'],
    onSelect: ['suspectCodeModels', 'jobRevisions',
      'partNumbers', 'serialNumbers',
      'lotNumbers', 'other1s', 'other2s', 'workAreas', 'posts'],
  },
  companyNames: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedCompanyNames);
    },
    conditionalDispatch(state) {
      return ConfigHelper.meetingRequirements(state, 'companyNames')
    },
    required: ['zone_ids'],
    query_param: 'company_name_ids',
    onInit: ['zone_ids'],
    onSelect: [],
  },
  departments: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedDepartments);
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'departments') },
    required: ['zone_ids'],
    query_param: 'department_ids',
    onInit: ['zone_ids'],
    onSelect: ['roles'],
  },
  tags: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedTags);
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'tags') },
    required: ['zone_ids'],
    query_param: 'tag_ids',
    onInit: ['zone_ids'],
    onSelect: ['jobs'],
  },
  ranks: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedTags);
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'ranks') },
    required: [],
    query_param: 'rank_ids',
    onInit: [],
    onSelect: [],
  },
  roles: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedRoles);
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'roles') },
    required: ['department_ids'],
    query_param: 'role_ids',
    onInit: ['department_ids', 'zone_ids'],
    onSelect: [],
  },
  jobRevisions: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedJobRevisions);
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'jobRevisions') },
    required: ['job_ids'],
    query_param: 'job_revision_ids',
    onInit: ['zone_ids', 'job_ids'],
    onSelect: ['suspectCodeModels'],
  },
  partNumbers: {
    customIdentifier: "name",
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedPartNumbers, this.customIdentifier);
    },
    conditionalDispatch(state) {
      return ConfigHelper.meetingRequirements(state, 'partNumbers')
    },
    required: ['job_ids'],
    query_param: 'part_numbers_constraint_ids',
    onInit: ['zone_ids', 'job_ids'],
    onSelect: [],
  },
  serialNumbers: {
    customIdentifier: "name",
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedSerialNumbers, this.customIdentifier);
    },
    conditionalDispatch(state) {
      return ConfigHelper.meetingRequirements(state, 'serialNumbers')
    },
    required: ['job_ids'],
    query_param: 'serial_numbers_constraint_ids',
    onInit: ['zone_ids', 'job_ids'],
    onSelect: [],
  },
  lotNumbers: {
    customIdentifier: "name",
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedLotNumbers, this.customIdentifier);
    },
    conditionalDispatch(state) {
      return ConfigHelper.meetingRequirements(state, 'lotNumbers')
    },
    required: ['job_ids'],
    query_param: 'lot_numbers_constraint_ids',
    onInit: ['zone_ids', 'job_ids'],
    onSelect: [],
  },
  other1s: {
    customIdentifier: "name",
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedOther1s, this.customIdentifier);
    },
    conditionalDispatch(state) {
      return ConfigHelper.meetingRequirements(state, 'other1s')
    },
    query_param: 'other1s_ids',
    onInit: ['zone_ids', 'job_ids'],
    required: ['job_ids'],
    onSelect: [],
  },
  other2s: {
    customIdentifier: "name",
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedOther2s, this.customIdentifier);
    },
    conditionalDispatch(state) {
      return ConfigHelper.meetingRequirements(state, 'other2s')
    },
    query_param: 'other2s_ids',
    onInit: ['zone_ids', 'job_ids'],
    required: ['job_ids'],
    onSelect: [],
  },
  suspectCodeModels: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedSuspectCodeModels);
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'suspectCodeModels') },
    query_param: 'defect_code_model_ids',
    onInit: ['zone_ids', 'job_ids', 'job_revision_ids'],
    required: ['zone_ids'],
    onSelect: ['suspectCodes']
  },
  suspectCodes: {
    customIdentifier: "name",
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedSuspectCodes, this.customIdentifier);
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'suspectCodes') },
    query_param: 'suspect_codes',
    onInit: ['zone_ids', 'job_ids', 'job_revision_ids', 'defect_code_model_ids'],
    required: ['job_ids', 'defect_code_model_ids'],
    onSelect: [],
  },
  users: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedUsers);
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'users') },
    query_param: 'user_ids',
    onInit: ['zone_ids'],
    required: [],
    onSelect: [],
  },
  binUsers: {
    getValues(state) {
      return SelectHelper.getSelectValues(state.selectedBinUsers);
    },
    conditionalDispatch(state) { return ConfigHelper.meetingRequirements(state, 'binUsers') },
    query_param: 'bin_user_ids',
    onInit: ['zone_ids'],
    required: ['zone_ids'],
    onSelect: [],
  }
}

export { defaultParams, defaultConfig }
