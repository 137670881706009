export default {
  defaultParamsMap() {
    return {
      zone: "zone_ids",
      job: "job_ids",
      work_area: "work_area_ids",
      post: "post_ids",
      job_revision: "job_revision_ids",
      defect_code_model: "defect_code_model_ids",
      audit_questionnaire_group: "questionnaire_group_ids",
      audit_questionnaire: "questionnaire_ids",
      shift_description: "shift_description_ids",
      user: "user_ids",
      suspect_code: "suspect_codes",
      company_name: "company_name_ids",
      part_number: "part_numbers_constraint_ids",
      serial_number: "serial_numbers_constraint_ids",
      lot_number: "lot_numbers_constraint_ids",
      other1: "other1s_ids",
      other2: "other2s_ids",
      start_date: "start_date",
      end_date: "end_date",
      department: "department_ids",
      role: "role_ids",
      bin_user: "bin_user_ids",
      job_status: "job_status"
    }
  },
  defaultRevertParamsMap() {
    return {
      zone_ids: "zone",
      job_ids: "job",
      work_area_ids: "work_area",
      post_ids: "post",
      job_revision_ids: "job_revision",
      defect_code_model_ids: "defect_code_model",
      questionnaire_group_ids: "audit_questionnaire_group",
      questionnaire_ids: "audit_questionnaire",
      shift_description_ids: "shift_description",
      user_ids: "user",
      suspect_codes: "suspect_code",
      company_name_ids: "company_name",
      part_numbers_constraint_ids: "part_number",
      serial_numbers_constraint_ids: "serial_number",
      lot_numbers_constraint_ids: "lot_number",
      other1s_ids: "other1",
      other2s_ids: "other2",
      start_date: "start_date",
      end_date: "end_date",
      department_ids: "department",
      role_ids: "role",
      bin_user_ids: "bin_user",
      job_status: "job_status"
    }
  },
}