import invert from 'lodash/invert'

export default {
  getSelectValues(selected, value = "value") {
    if (this.isArray(selected)) {
      let mapSelected = selected.map(x => x[value]);
      if (mapSelected.length === 0) return null
      return mapSelected
    } else if (selected == null) {
      return null
    }
    return selected[value]
  },
  meetingRequirements(state, type) {
    return state.config[type].required.every(req => state.params[req])
  },
  hasLabel(selectedJobs, label) {
    if (selectedJobs == null) {
      return false
    } else if (this.isArray(selectedJobs)) {
      return selectedJobs.some(currentValue => {
        let attribute = currentValue.attributes[label]
        return attribute && attribute.length > 0
      })
    } else {
      let attribute = selectedJobs.attributes[label]
      return attribute && attribute.length > 0
    }
  },
  isArray(obj) {
    return !!obj && obj.constructor === Array;
  },
  defaultParamsMap() {
    return {
      zone: "zone_ids",
      job: "job_ids",
      tag: "tag_ids",
      rank: "rank_ids",
      job_revision: "job_revision_ids",
      defect_code_model: "defect_code_model_ids",
      audit_questionnaire_group: "questionnaire_group_ids",
      audit_questionnaire: "questionnaire_ids",
      shift_description: "shift_description_ids",
      time_frame: "time_frame_ids",
      user: "user_ids",
      suspect_code: "suspect_codes",
      company_name: "company_name_ids",
      part_number: "part_numbers_constraint_ids",
      serial_number: "serial_numbers_constraint_ids",
      lot_number: "lot_numbers_constraint_ids",
      other1: "other1s_ids",
      other2: "other2s_ids",
      work_area: "work_area_ids",
      post: "post_ids",
      start_date: "start_date",
      end_date: "end_date",
      department: "department_ids",
      role: "role_ids",
      device: "device",
      job_status: "job_status",
      search_by: "search_by"
    }
  },
  defaultRevertParamsMap() {
    return invert(this.defaultParamsMap())
  },
  mapParams(params, paramsMap) {
    let mappedParams = {}
    Object.keys(params).forEach(key => {
      let value = params[key]
      let newKey = paramsMap[key]

      mappedParams[newKey || key] = value
    })

    return mappedParams
  }
}